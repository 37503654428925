import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'AppContentPage',
    component: () => import("../container/ContentPage.vue")
  }, {
    // 用户隐私协议
    path: '/protocol',
    // component: () => import("../container/protocol/UserAgreementPage.vue"),
    children: [
      {
        // 儿童个人信息保护规则
        path: "childInfoRules",
        component: () => import("../container/protocol/ChildInfoRulesPage.vue"),
        name: "ChildInfoRulesPage",
        meta: { title: '儿童个人信息保护规则' }
      }, {
        // SDK清单
        path: "SDKList",
        component: () => import("../container/protocol/SDKListPage.vue"),
        name: "SDKListPage",
        meta: { title: 'SDK清单' }
      }, {
        // 用户隐私政策协议
        path: "userAgreement",
        component: () => import("../container/protocol/UserAgreementPage.vue"),
        name: "UserAgreementPage",
        meta: { title: '用户隐私政策协议' }
      }, {
        // 注册用户服务协议
        path: "userServiceAgreement",
        component: () => import("../container/protocol/UserServiceAgreementPage.vue"),
        name: "UserServiceAgreementPage",
        meta: { title: '注册用户服务协议' }
      }
    ],
    meta: {
      // auth: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log('router-before', to, from)
//   next()
// })

router.afterEach((to, from) => {
  // console.log('router-after', to, from)
  if (to.meta.title) {
    // 遍历meta改变title
    document.title = to.meta.title
  }
})

export default router
